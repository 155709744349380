import React, { useEffect, useRef, useState,useCallback } from 'react';
import { Button, Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AgoraRTC from 'agora-rtc-sdk-ng';
import { useParams } from 'react-router-dom';
import { getCaseDetails, getLocation, saveLatLng } from '../../redux/actions/CaseDetailsActions';
import { useDispatch, useSelector } from 'react-redux';
import AuthLayout from '../auth/AuthLayout';
import FormInput from '../../Components/FormInput';
import Preloader from '../../Components/Preloader';
import axios from 'axios';
import { decodeBase64 } from '../../util/generalUtills';
import { startRecordingAgora, stopRecordingAgora } from '../../redux/actions/AgoraActions';
import { useSocketContext } from '../../Context/SocketContext';
const client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });

const VideoKyc = () => {
    const { socket } = useSocketContext();
    const { urlData } = useParams();
    const urlJsonData = decodeBase64(urlData);
    const { id, host, userId } = JSON.parse(urlJsonData);
    const dispatch = useDispatch();
    const {
        caseDetailsReducer: { loadingCaseDetails, locationDetails, errorsCaseDetails, caseDetails },
        agoraReducer: { loadingAgora, errorsAgora, stopRecording, startRecording }
    } = useSelector(state => state)
    const APP_ID = '9baeaff876964dd094bd5f4585f1be79'; // Replace with your Agora App ID
    const TOKEN = null; // Replace with your Agora token (if you have one)
    const CHANNEL = `${id}`; // Replace with your Agora channel name
    const { t } = useTranslation();
    const [videocall, setVideocall] = useState(false);
    const [isDataValid, setDataValid] = useState(true); // You might set this based on user role
    const [isHost, setHost] = useState(true); // You might set this based on user role
    const [isPinned, setPinned] = useState(false);
    const [username, setUsername] = useState('');
    const [joined, setJoined] = useState(false);
    const [micMuted, setMicMuted] = useState(false);
    const [currentTime, setCurrentTime] = useState('');
    const [latitude, setLatitude] = useState('Fetching...');
    const [longitude, setLongitude] = useState('Fetching...');
    const [address, setAddress] = useState('Fetching address...');
    const [screenTrack, setScreenTrack] = useState(null);
    const [locationPermissionGranted, setLocationPermissionGranted] = useState(false); // New state variable
    const [cameraPermission, setCameraPermission] = useState(null);
    const [micPermission, setMicPermission] = useState(null);
    const [error, setError] = useState(null);
    const [isRecord, setRecord] = useState(false);


    const localVideoRef = useRef(null);
    const remoteVideoRef = useRef(null);
    const localVideoTrackRef = useRef(null);
    const localAudioTrackRef = useRef(null);
    const [resourceId, setResourceId] = useState(null);
    const [sid, setSid] = useState(null);
    const [uid, setUid] = useState(null); // State variable for UID
    const APP_CERTIFICATE = '63c11bc26af94258a2d0a2411543268f';
    const CUSTOMER_ID = '9ba6356a28d84d18a540ed373f20fbc4';
    const CUSTOMER_CERTIFICATE = '554aa90312f34b678e0c5a82664ac0bf';

    const checkPermissions = async () => {
        try {
            const cameraStatus = await navigator.permissions.query({ name: 'camera' });
            const micStatus = await navigator.permissions.query({ name: 'microphone' });

            setCameraPermission(cameraStatus.state);
            setMicPermission(micStatus.state);

            cameraStatus.onchange = () => setCameraPermission(cameraStatus.state);
            micStatus.onchange = () => setMicPermission(micStatus.state);
        } catch (err) {
            setError('Permission API is not supported in your browser');
        }
    };

    const requestPermissions = async () => {
        try {
            await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            checkPermissions();
        } catch (err) {
            setError(err.message);
        }
    };

    const renderPermissionStatus = (permission) => {
        switch (permission) {
            case 'granted':
                return <span style={{ color: 'green' }}>Granted</span>;
            case 'denied':
                return <span style={{ color: 'red' }}>Denied</span>;
            case 'prompt':
                return <span style={{ color: 'orange' }}>Prompt</span>;
            default:
                return <span style={{ color: 'gray' }}>Unknown</span>;
        }
    };



    useEffect(() => {
        checkPermissions();
        requestPermissions();
        // Generate and set the UID when the component mounts or when starting the call
        const generatedUid = Math.floor(1000 + Math.random() * 9000).toString();
        setUid(generatedUid);
    }, []);
    const handleCloseWindow = () => {
        window.close();
    };

    const handleReloadWindow = () => {
        window.location.reload();
    };

    useEffect(() => {
        // console.log('urlData',urlData);
        // console.log('urlJsonData',urlJsonData);
        if (id) {
            dispatch(getCaseDetails(id, host, userId));
            setHost(host)
        }
    }, [id]);

    useEffect(() => {
        if (startRecording && Object.keys(startRecording).length > 0) {
            console.log('startRecording', startRecording);
            setResourceId(startRecording?.resourceId);
            setSid(startRecording?.sId);
            setRecord(true);
        }
    }, [startRecording])

    useEffect(() => {
        if (stopRecording && Object.keys(stopRecording).length > 0) {
            console.log('stopRecording', stopRecording);
            setRecord(false);
        }

    }, [stopRecording])

    useEffect(() => {
        console.log('caseDetails', caseDetails);
        if (caseDetails?.status === "OK") {
            //setHost(caseDetails?.host);
        }
    });

    useEffect(() => {
        console.log('locationDetails', locationDetails);
    }, [locationDetails])

    useEffect(() => {
        if (isHost == true && client) {
            setVideocall(true)
            setJoined(true)
        }
        else {
            setVideocall(false)
        }
    }, [isHost])

    useEffect(() => {
        const handleResize = () => {
            document.documentElement.style.setProperty('--viewport-height', `${window.innerHeight}px`);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Set initial height

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        const formatDate = (date) => {
            const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
            return new Intl.DateTimeFormat('en-GB', options).format(date);
        };

        const updateTime = () => {
            setCurrentTime(formatDate(new Date()));
        };

        updateTime(); // Initial call
        const interval = setInterval(updateTime, 1000); // Update every second

        return () => clearInterval(interval);
    }, []);


    useEffect(() => {
        const updateTime = () => {
            dispatch(getLocation(id));
        };

        dispatch(getLocation(id));
        const interval = setInterval(updateTime, 30000); // Update every second

        return () => clearInterval(interval);
    }, []);


    useEffect(() => {
        const fetchLocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(async (position) => {
                    const { latitude, longitude } = position.coords;
                    setLatitude(latitude.toFixed(4));
                    setLongitude(longitude.toFixed(4));
                    if (host == null) {
                        dispatch(saveLatLng(id, latitude, longitude));
                    }
                    setLocationPermissionGranted(true);
                }, (error) => {
                    console.error('Error getting location:', error);
                    setLatitude('N/A');
                    setLongitude('N/A');
                    setAddress(error?.message);
                    setLocationPermissionGranted(false);
                });
            } else {
                console.error('Geolocation is not supported by this browser.');
                setLatitude('N/A');
                setLongitude('N/A');
                setAddress('Unable to fetch address');
                setLocationPermissionGranted(true);
            }
        };

        const fetchAddress = async (lat, lng) => {
            try {
                const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&addressdetails=1`);
                const data = await response.json();
                setAddress(data.display_name || 'Address not found');
            } catch (error) {
                console.error('Error fetching address:', error);
                setAddress('Unable to fetch address');
            }
        };

        fetchLocation();
        const locationInterval = setInterval(fetchLocation, 30000); // Update location every 30 seconds

        return () => clearInterval(locationInterval);
    }, []);
    useEffect(() => {
        if (videocall) {
            const init = async () => {
                try {
                    if (client.connectionState === 'CONNECTED' || client.connectionState === 'CONNECTING') {
                        console.warn("Client already connecting or connected.");
                        return;
                    }

                    client.on('user-published', async (user, mediaType) => {
                        try {
                            const remoteUserUID = user.uid;
                            await client.subscribe(user, mediaType);
                            console.log('Successfully subscribed', user, mediaType);
                            if (mediaType === 'video') {
                                const remoteVideoTrack = user.videoTrack;
                                remoteVideoTrack.play(remoteVideoRef.current);
                            }
                            if (mediaType === 'audio') {
                                const remoteAudioTrack = user.audioTrack;
                                remoteAudioTrack.play();
                            }
                            dispatch(startRecordingAgora(host, id, uid, remoteUserUID))
                        } catch (error) {
                            console.error('Error subscribing to user:', error);
                        }
                    });

                    client.on('user-unpublished', (user) => {
                        console.log(user.uid + ' has left the channel');
                        dispatch(stopRecordingAgora(host, id, userId, resourceId, sid))
                    });

                    const uid = await client.join(APP_ID, CHANNEL, TOKEN, null);
                    const localVideoTrack = await AgoraRTC.createCameraVideoTrack();
                    const localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
                    localVideoTrackRef.current = localVideoTrack;
                    localAudioTrackRef.current = localAudioTrack;
                    localVideoTrack.play(localVideoRef.current);
                    await client.publish([localVideoTrack, localAudioTrack]);

                    setJoined(true);
                    //handleScreenShare();
                } catch (error) {
                    console.error('Error during Agora RTC client initialization:', error);
                    // Additional error handling if needed
                }
            };

            init();

            return () => {
                if (joined) {
                    client.leave().then(() => {
                        console.log('Left the channel');
                        localVideoTrackRef.current?.stop();
                        localVideoTrackRef.current?.close();
                        localAudioTrackRef.current?.stop();
                        localAudioTrackRef.current?.close();
                        setJoined(false);
                    }).catch(error => {
                        console.error('Error leaving the channel:', error);
                    });
                }
            };
        }
    }, [videocall]);
    useEffect(() => {
        console.log("socket");
        console.log(socket);
        socket.emit("subscribe", { room: uid });

        return () => {
            // socket.off("adminchat");
        };
    }, []);
   



    // useEffect(() => {
    //     if (videocall) {
    //         const init = async () => {
    //             try {
    //                 client.on('user-published', async (user, mediaType) => {
    //                     try {
    //                         const remoteUserUID = user.uid;
    //                         //console.log('remoteUserUID',remoteUserUID);
    //                         await client.subscribe(user, mediaType);
    //                         console.log('Successfully subscribed', user, mediaType);
    //                         if (mediaType === 'video') {
    //                             const remoteVideoTrack = user.videoTrack;
    //                             remoteVideoTrack.play(remoteVideoRef.current);
    //                         }
    //                         if (mediaType === 'audio') {
    //                             const remoteAudioTrack = user.audioTrack;
    //                             remoteAudioTrack.play();
    //                         }
    //                         dispatch(startRecordingAgora(host,id,uid,remoteUserUID))
    //                     } catch (error) {
    //                         console.error('Error subscribing to user:', error);
    //                     }
    //                 });

    //                 client.on('user-unpublished', (user) => {
    //                     console.log(user.uid + ' has left the channel');
    //                     dispatch(stopRecordingAgora(host,id,userId,resourceId,sid))
    //                 });

    //                 const uid = await client.join(APP_ID, CHANNEL, TOKEN, null);
    //                 const localVideoTrack = await AgoraRTC.createCameraVideoTrack();
    //                 const localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    //                 localVideoTrackRef.current = localVideoTrack;
    //                 localAudioTrackRef.current = localAudioTrack;
    //                 localVideoTrack.play(localVideoRef.current);
    //                 await client.publish([localVideoTrack, localAudioTrack]);

    //                 setJoined(true);
    //                 //handleScreenShare();
    //             } catch (error) {
    //                 console.error('Error during Agora RTC client initialization:', error);
    //                 // Additional error handling if needed
    //             }
    //         };

    //         init();

    //         return () => {
    //             client.leave().then(() => {
    //                 console.log('Left the channel');
    //                 localVideoTrackRef.current?.stop();
    //                 localVideoTrackRef.current?.close();
    //                 localAudioTrackRef.current?.stop();
    //                 localAudioTrackRef.current?.close();
    //                 setJoined(false);
    //             }).catch(error => {
    //                 console.error('Error leaving the channel:', error);
    //             });
    //         };
    //     }
    // }, [videocall]);

    const handleScreenShare = async () => {
        if (!screenTrack) {
            try {
                const screenTrack = await AgoraRTC.createScreenVideoTrack({
                    encoderConfig: "1080p_1",
                    optimizationMode: "detail",
                    screenSourceType: "window", // 'window' specifies the type of screen source
                });
                setScreenTrack(screenTrack);
                await client.publish(screenTrack);
                screenTrack.play(localVideoRef.current);
            } catch (error) {
                console.error('Error starting screen share:', error);
            }
        } else {
            screenTrack.close();
            setScreenTrack(null);
        }
    };



// Ensure handleEndCall is defined outside of useEffect
const handleEndCall = useCallback(async () => {
    try {
        if (client.connectionState === 'CONNECTED') {
            socket.emit("leaveVideo", uid);
            // Leave the channel
            await client.leave();

            // Stop and close local video track
            if (localVideoTrackRef.current) {
                localVideoTrackRef.current.stop();
                localVideoTrackRef.current.close();
            }

            // Stop and close local audio track
            if (localAudioTrackRef.current) {
                localAudioTrackRef.current.stop();
                localAudioTrackRef.current.close();
            }

            console.log('Successfully left the channel');
        } else {
            console.log('Client is not connected to any channel');
        }
    } catch (error) {
        console.error('Error leaving the channel:', error);
    }
}, []);
useEffect(() => {
    const handleLeaveCall = () => {
        console.log("socket auto leave");
        handleEndCall().then(() => {
            setJoined(false);
            if (!isHost) {
                setVideocall(false);
            }
        });
    };

    socket.on("leaveCall", handleLeaveCall);

    return () => {
        socket.off("leaveCall", handleLeaveCall);
    };
}, [handleEndCall, isHost,socket]);
    // const handleEndCall = async () => {
     
    //    // if (joined) {
    //         socket.emit("leaveVideo", uid);
    //         await client.leave();
    //         localVideoTrackRef.current?.stop();
    //         localVideoTrackRef.current?.close();
    //         localAudioTrackRef.current?.stop();
    //         localAudioTrackRef.current?.close();
    //         setJoined(false);
    //         if(!isHost) {
    //             setVideocall(false);
    //         }

    //    // }
    //     //  dispatch(stopRecordingAgora(host, id, userId, resourceId, sid));
    //     handleCloseWindow();
    // };

    const handleToggleMic = () => {
        if (micMuted) {
            localAudioTrackRef.current?.setEnabled(true);
        } else {
            localAudioTrackRef.current?.setEnabled(false);
        }
        setMicMuted(!micMuted);
    };

    return (
        <>
            {caseDetails?.status !== "OK" && (<Preloader msg={caseDetails?.msg} />)}
            {caseDetails?.status === "OK" && (<>
                {videocall == true ?
                    <>
                        <Row>
                            <Col>
                                <Card style={{ backgroundColor: 'black', borderRadius: 0 }}>
                                    <Card.Body>
                                        <Row>
                                            <Col lg={12}>
                                                <div style={{ height: '95vh', overflow: 'hidden', display: 'flex', flexDirection: 'column', borderRadius: 10 }}>
                                                    <div style={{ position: 'relative', flex: 1 }}>
                                                        {isHost ? (
                                                           <>
                                                            <div ref={remoteVideoRef} style={{
                                                                width: '100%',
                                                                height: '100%',
                                                                backgroundColor: 'black',
                                                                position: 'relative',
                                                                overflow: 'hidden'
                                                            }}></div>
                                                            <div ref={localVideoRef} style={{display:'none'}}></div>
                                                           </>
                                                        ) : (
                                                            <>
                                                            
                                                                <div ref={localVideoRef} style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    backgroundColor: 'black',
                                                                    position: 'relative',
                                                                    overflow: 'hidden'
                                                                }}></div>
                                                                <div ref={remoteVideoRef} style={{display:'none'}}></div>
                                                            </>
                                                        )}

                                                        <>
                                                            <div style={{
                                                                position: 'absolute',
                                                                bottom: '10px',
                                                                left: '50%',
                                                                transform: 'translateX(-50%)',
                                                                zIndex: 1000
                                                            }}>

                                                                <Button onClick={handleEndCall} variant="danger" style={{ marginLeft: '10px' }}>End Calls</Button>
                                                                <Button onClick={handleToggleMic} variant="secondary" style={{ marginLeft: '10px' }}>
                                                                    {micMuted ? 'Unmute Mic' : 'Mute Mic'}
                                                                </Button>
                                                            </div>
                                                            {isHost && (<>
                                                                <div style={{
                                                                    position: 'absolute',
                                                                    top: '0px',
                                                                    width: '100%',
                                                                    right: '0px',
                                                                    zIndex: 1000,
                                                                    color: 'white',
                                                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                                    padding: '5px',
                                                                    borderRadius: '0px',
                                                                    textAlign: 'center'
                                                                }}>
                                                                    <div>{`Claim Number : ${caseDetails?.case_summary?.claim_number}`}</div>
                                                                    <div>{currentTime}</div>
                                                                    <div>{`Latitude: ${locationDetails?.lat}`} {`Longitude: ${locationDetails?.lng}`}</div>
                                                                    <div>{locationDetails?.address}</div>
                                                                    {isRecord && (<>
                                                                        <div>Video is now been recorded</div>
                                                                    </>)}
                                                                </div>
                                                            </>)}

                                                        </>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </>
                    :
                    <>
                        <AuthLayout
                            helpText={t(
                                `Welcome ${caseDetails?.case_summary?.patient_name},`
                            )}

                        >
                            <h5>
                                We are delighted to inform you that your Video KYC appointment is now ready. Please join the session by clicking the button below:
                            </h5>
                            <h5>
                                Before you start the session, kindly verify the following details to ensure a smooth process:
                            </h5>
                            <FormInput
                                label={t("Claim Number")}
                                type="text"
                                value={caseDetails?.case_summary?.claim_number}
                                placeholder="Enter your Username"
                                containerClass={"mb-3"}
                            />
                            <FormInput
                                label={t("Claim Type")}
                                type="text"
                                value={caseDetails?.case_summary?.claim_type_name}
                                placeholder="Enter your Username"
                                containerClass={"mb-3"}
                            />
                            <FormInput
                                label={t("Insurance Company")}
                                type="text"
                                value={caseDetails?.case_summary?.company_name}
                                placeholder="Enter your Username"
                                containerClass={"mb-3"}
                            />

                            <FormInput
                                label={t("Hospital Name")}
                                type="text"
                                value={caseDetails?.case_summary?.hospital_name}
                                placeholder="Enter your Username"
                                containerClass={"mb-3"}
                            />
                            {locationPermissionGranted == false && (<>
                                <span >Error : Permission denied. Please enable location access in your browser settings.</span>
                                <div className="text-center d-grid">
                                    <Button variant="primary" type="submit" onClick={handleReloadWindow} >
                                        {t("Validate Permission")}
                                    </Button>
                                </div>
                            </>)}

                            {error != null && (<>
                                <span >Error : {error}</span>
                                <div className="text-center d-grid">
                                    <Button variant="primary" type="submit" onClick={handleReloadWindow} >
                                        {t("Validate Permission")}
                                    </Button>
                                </div>
                            </>)}


                            {locationPermissionGranted && error == null && (<>
                                <div className="text-center d-grid">
                                    <Button variant="primary" type="submit" onClick={() => setVideocall(true)} >
                                        {t("Start Video Kyc")}
                                    </Button>
                                </div>
                            </>)}


                        </AuthLayout>
                    </>
                }
            </>)}
        </>
    );
};

export default VideoKyc;
