// constants
import {
    LayoutMode,
    LayoutTypes,
    LayoutColor,
    LayoutWidth,
    MenuPositions,
    SideBarTheme,
    SideBarTypes,
    TopbarTheme,
    TwoColumnTheme,
  } from "../constants/layout";
  
  const LayoutActionTypes = {
    CHANGE_TWOCOLUMN_THEME: "@@layout/CHANGE_TWOCOLUMN_THEME",
    CHANGE_LAYOUT_MODE: "@@layout/CHANGE_LAYOUT_MODE",
    CHANGE_LAYOUT: "@@layout/CHANGE_LAYOUT",
    CHANGE_LAYOUT_COLOR: "@@layout/CHANGE_LAYOUT_COLOR",
    CHANGE_LAYOUT_WIDTH: "@@layout/CHANGE_LAYOUT_WIDTH",
    CHANGE_MENU_POSITIONS: "@@layout/CHANGE_MENU_POSITIONS",
    CHANGE_SIDEBAR_THEME: "@@layout/CHANGE_SIDEBAR_THEME",
    CHANGE_SIDEBAR_TYPE: "@@layout/CHANGE_SIDEBAR_TYPE",
    TOGGLE_SIDEBAR_USER_INFO: "@@layout/TOGGLE_SIDEBAR_USER_INFO",
    TOGGLE_TWO_TONE_ICONS: "@@layout/TOGGLE_TWO_TONE_ICONS",
    CHANGE_TOPBAR_THEME: "@@layout/CHANGE_TOPBAR_THEME",
    SHOW_RIGHT_SIDEBAR: "@@layout/SHOW_RIGHT_SIDEBAR",
    HIDE_RIGHT_SIDEBAR: "@@layout/HIDE_RIGHT_SIDEBAR",
  };
  
  const LayoutStateTypes = {
    twoColumnTheme: TwoColumnTheme.TWOCOLUMN_LIGHT,
    layoutMode: LayoutMode.LAYOUT_DEFAULT,
    layoutType: LayoutTypes.LAYOUT_VERTICAL,
    layoutColor: LayoutColor.LAYOUT_COLOR_LIGHT,
    layoutWidth: LayoutWidth.LAYOUT_WIDTH_FLUID,
    menuPosition: MenuPositions.MENU_POSITION_FIXED,
    leftSideBarTheme: SideBarTheme.LEFT_SIDEBAR_THEME_LIGHT,
    leftSideBarType: SideBarTypes.LEFT_SIDEBAR_TYPE_DEFAULT,
    showTwoToneIcons: false,
    showSidebarUserInfo: false,
    topbarTheme: TopbarTheme.TOPBAR_THEME_LIGHT,
    isOpenRightSideBar: false,
  };
  
  export { LayoutActionTypes, LayoutStateTypes };
  