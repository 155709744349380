import axios from "axios"

export const getCaseDetails = (id,host,userId) => async(dispatch) => {
    try {
        dispatch({type:"CASE_DETAILS_PENDING"})
        let data=`${id}`
        if(host)
        {
            data=`${id}/${host}/${userId}`
        }
        const response = await axios.get(`/Case-Details/${data}`)
        console.log('success response',response.data)
        dispatch({type:"CASE_DETAILS_FULFILLED",payload:response.data})
        
    } catch (error) {
        console.log('Error response',error.response.data)
        dispatch({type:"CASE_DETAILS_FAILED",payload:error.response})
    }
} 

export const saveLatLng = (id,lat,lng) => async(dispatch) => {
    try {
        dispatch({type:"CASE_DETAILS_PENDING"})
        
        const response = await axios.get(`/api/Save-Location/${id}/${lat}/${lng}`)
        //console.log('success response',response.data)
        //dispatch({type:"CASE_DETAILS_FULFILLED",payload:response.data})
        
    } catch (error) {
        console.log('Error response',error.response.data)
        dispatch({type:"CASE_DETAILS_FAILED",payload:error.response})
    }
} 


export const getLocation = (id) => async(dispatch) => {
    try {
        dispatch({type:"GET_LOCATION_PENDING"})
        
        const response = await axios.get(`/api/Get-Location/${id}`)
        console.log('success response',response.data)
        dispatch({type:"GET_LOCATION_FULFILLED",payload:response.data})
        
    } catch (error) {
        console.log('Error response',error.response.data)
        dispatch({type:"GET_LOCATION_FAILED",payload:error.response})
    }
} 