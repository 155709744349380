const initialState = {
    caseDetails : [],
    locationDetails : [],
    loadingCaseDetails: false,
    errorsCaseDetails: {}
}

const  caseDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "CASE_DETAILS_PENDING":
        case "GET_LOCATION_PENDING":
            return { ...state, loadingCaseDetails: true };

        case "CASE_DETAILS_FULFILLED":
            return {
                ...state,
                loadingCaseDetails: false,
                caseDetails: action.payload
            };

        case "GET_LOCATION_FULFILLED":
            return {
                ...state,
                loadingCaseDetails: false,
                locationDetails: action.payload
            };

        case "CASE_DETAILS_FAILED":
        case "GET_LOCATION_FAILED":
            return {
                ...state,
                loadingCaseDetails: false,
                errorsCaseDetails: action.payload
            };

        default:
            return state;
    }
}

export default caseDetailsReducer;