const initialState = {
    startRecording : {},
    stopRecording : {},
    loadingAgora: false,
    errorsAgora: {}
}

const  agoraReducer = (state = initialState, action) => {
    switch (action.type) {
        case "START_RECORDING_PENDING":
        case "STOP_RECORDING_PENDING":
            return { ...state, loadingAgora: true };

        case "START_RECORDING_FULFILLED":
            return {
                ...state,
                loadingAgora: false,
                startRecording: action.payload
            };

        case "STOP_RECORDING_FULFILLED":
            return {
                ...state,
                loadingAgora: false,
                stopRecording: action.payload
            };

        case "START_RECORDING_FAILED":
        case "STOP_RECORDING_FAILED":
            return {
                ...state,
                loadingAgora: false,
                errorsAgora: action.payload
            };

        default:
            return state;
    }
}

export default agoraReducer;