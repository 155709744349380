import { configureStore } from "@reduxjs/toolkit"
import AuthReducer from "./reducers/AuthReducer"
import layoutReducer from "./reducers/LayoutReducer";
import caseDetailsReducer from "./reducers/CaseDetailsReducer";
import agoraReducer from "./reducers/AgoraReducer";

import {thunk} from 'redux-thunk';
const Store = configureStore({
    reducer: {
        AuthReducer,
        layoutReducer,
        caseDetailsReducer,
        agoraReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(thunk),
})

export default Store