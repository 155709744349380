import { BrowserRouter, Route, Routes } from "react-router-dom";

import VideoKyc from "./pages/chat/VideoKyc";
import { ToastContainer } from "react-toastify";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Routes>
        <Route path="/Kyc/:urlData" element={<VideoKyc />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
