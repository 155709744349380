import axios from "axios"
import { nodeUrl } from "../../constants/constant"

export const startRecordingAgora = (host,channelId,uId,remoteUserUID) => async(dispatch) => {
    try {
        dispatch({type:"START_RECORDING_PENDING"})
        
        const response = await axios.post(`${nodeUrl}/api/admin/v1/startRecording`,{"channelName": `${channelId}`,
            "uid":`${uId}`,remoteUserUID:`${remoteUserUID}`})
        console.log('success response',response.data)
        dispatch({type:"START_RECORDING_FULFILLED",payload:response.data})
        
    } catch (error) {
        console.log('Error response',error.response.data)
        dispatch({type:"START_RECORDING_FAILED",payload:error.response})
    }
} 

export const stopRecordingAgora = (host,channelId,uId,resourceId,sId) => async(dispatch) => {
    try {
        const data={ 
            
        }
        dispatch({type:"STOP_RECORDING_PENDING"})
        
        const response = await axios.post(`${nodeUrl}/api/admin/v1/stopRecording`,{
            "channelName": `${channelId}`,
            "uid":`${uId}`,
            "resourceId":`${resourceId}`,
            "sId":`${sId}`
        })
        //console.log('success response',response.data)
        dispatch({type:"STOP_RECORDING_FULFILLED",payload:response.data})
        
    } catch (error) {
        console.log('Error response',error.response.data)
        dispatch({type:"STOP_RECORDING_FAILED",payload:error.response})
    }
} 

